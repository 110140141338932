import React from 'react';
import * as CustomCode from '../CustomCode';
import * as GlobalStyles from '../GlobalStyles.js';
import * as NinoxApi from '../apis/NinoxApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import getDate_Day from '../global-functions/getDate_Day';
import getDate_Month from '../global-functions/getDate_Month';
import getDate_String from '../global-functions/getDate_String';
import getDate_Year from '../global-functions/getDate_Year';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  Button,
  Divider,
  Picker,
  ScreenContainer,
  SectionHeader,
  SectionList,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { FlashList } from '@shopify/flash-list';
import {
  ActivityIndicator,
  Image,
  ScrollView,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import { Fetch } from 'react-request';

const CatalogueScreen = props => {
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const setDeliveryDate_today = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    return tomorrow.setDate(tomorrow.getDate() + 1);
  };

  const emptytest = array => {
    const arraylength = array.length;
    const test = arraylength == 0 ? true : false;
    return test;
  };

  const LivraisonRemplie = datePicker => {
    let date = datePicker;
    let response = date > 0 ? false : true;
    return response;
  };

  const filterList_search = list => {
    // console.log(list,textInputValue)
    let lowerfiltertext = textInputValue.toLowerCase();
    let newList = list.filter(item =>
      item.nom.toLowerCase().includes(lowerfiltertext)
    );
    // console.log(newList)
    return newList;
  };

  const filterList_category = (list, category) => {
    return list.filter(item => item.catid === category);
  };

  const { theme } = props;
  const { navigation } = props;

  const [datePickerValue, setDatePickerValue] = React.useState(new Date());
  const [new_datePickerValue, setNew_datePickerValue] = React.useState(
    new Date()
  );
  const [textInputValue, setTextInputValue] = React.useState('');

  return (
    <ScreenContainer
      style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
      hasSafeArea={true}
      scrollable={false}
    >
      {/* view_datepicker */}
      <View
        style={StyleSheet.applyWidth(
          {
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 10,
            marginLeft: 20,
            marginRight: 20,
            marginTop: 10,
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            { maxWidth: '80%', width: '80%' },
            dimensions.width
          )}
        >
          <NinoxApi.FetchJoursGET>
            {({ loading, error, data, refetchJours }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <>
                  {/* Date_piker_new */}
                  <>
                    {!emptytest(Constants['ShoppingCart']) ? null : (
                      <Picker
                        onValueChange={newDatePikerNewValue => {
                          try {
                            setGlobalVariableValue({
                              key: 'deliverydate',
                              value: newDatePikerNewValue,
                            });
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          { fontSize: 16 },
                          dimensions.width
                        )}
                        options={fetchData}
                        label={'Livraison pour'}
                        iconSize={16}
                        leftIconMode={'inset'}
                        leftIconName={'Feather/calendar'}
                        placeholder={'Choisir une date'}
                        rightIconName={'Feather/chevron-down'}
                        type={'underline'}
                      />
                    )}
                  </>
                </>
              );
            }}
          </NinoxApi.FetchJoursGET>
          <>
            {emptytest(Constants['ShoppingCart']) ? null : (
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors.strong,
                    fontFamily: 'PTSerif_700Bold',
                    fontSize: 16,
                    textDecorationLine: 'underline',
                  },
                  dimensions.width
                )}
              >
                {'Livraison pour '}
                {getDate_String(Constants['deliverydate'])}
              </Text>
            )}
          </>
          <>
            {emptytest(Constants['ShoppingCart']) ? null : (
              <Text
                style={StyleSheet.applyWidth(
                  { color: theme.colors.strong },
                  dimensions.width
                )}
              >
                {'Pour modifier la livraison, \nannuler la commande en cours'}
              </Text>
            )}
          </>
        </View>
        <Image
          style={StyleSheet.applyWidth(
            { height: 60, minWidth: '20%', width: 60 },
            dimensions.width
          )}
          resizeMode={'cover'}
          source={Images.CarotteDetouree}
        />
      </View>
      {/* view_filter */}
      <>
        {!Constants['deliverydate'] ? null : (
          <View
            style={StyleSheet.applyWidth(
              { marginBottom: 10, marginLeft: 20, marginRight: 20 },
              dimensions.width
            )}
          >
            {/* filter */}
            <TextInput
              onChangeText={newFilterValue => {
                try {
                  setTextInputValue(newFilterValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                {
                  borderBottomWidth: 1,
                  borderColor: theme.colors.divider,
                  borderLeftWidth: 1,
                  borderRadius: 8,
                  borderRightWidth: 1,
                  borderTopWidth: 1,
                  paddingBottom: 8,
                  paddingLeft: 8,
                  paddingRight: 8,
                  paddingTop: 8,
                },
                dimensions.width
              )}
              value={textInputValue}
              placeholder={'Rechercher'}
            />
          </View>
        )}
      </>
      {/* view_catalogue */}
      <>
        {!Constants['deliverydate'] ? null : (
          <ScrollView
            contentContainerStyle={StyleSheet.applyWidth(
              { flex: 1, marginLeft: 20, marginRight: 20 },
              dimensions.width
            )}
            bounces={true}
            showsVerticalScrollIndicator={true}
          >
            <NinoxApi.FetchGetProduitsGET
              dateday={getDate_Day(Constants['deliverydate'])}
              datemonth={getDate_Month(Constants['deliverydate'])}
              dateyear={getDate_Year(Constants['deliverydate'])}
            >
              {({ loading, error, data, refetchGetProduits }) => {
                const fetchData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <>
                    <>
                      {textInputValue ? null : (
                        <SectionList
                          renderItem={({ item, section }) => {
                            const sectionListData = item;
                            return (
                              <>
                                <SectionHeader>
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        backgroundColor:
                                          theme.colors['Background'],
                                        borderBottomWidth: 2,
                                        borderColor: theme.colors['Primary'],
                                        marginBottom: 5,
                                        marginTop: 10,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ],
                                          {
                                            color: theme.colors['Primary'],
                                            fontSize: 18,
                                            marginBottom: 5,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {section}
                                    </Text>
                                  </View>
                                </SectionHeader>

                                <View>
                                  {/* Product */}
                                  <Touchable
                                    onPress={() => {
                                      try {
                                        navigation.navigate(
                                          'BottomTabNavigator',
                                          {
                                            screen: 'Commande',
                                            params: {
                                              screen: 'ProduitScreen',
                                              params: {
                                                precommande:
                                                  sectionListData?.precommande,
                                                productID: sectionListData?.id,
                                                nom: sectionListData?.nom,
                                                unit: sectionListData?.unit,
                                                conditionning:
                                                  sectionListData?.conditionnement,
                                                prix: sectionListData?.prix,
                                                provenane:
                                                  sectionListData?.provenance,
                                                note: sectionListData?.note,
                                              },
                                            },
                                          }
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    style={StyleSheet.applyWidth(
                                      {
                                        maxWidth: '100%',
                                        minHeight: 50,
                                        minWidth: '100%',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'flex-start',
                                          flexDirection: 'row',
                                          justifyContent: 'space-between',
                                          paddingBottom: 8,
                                          paddingTop: 8,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Libellé */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignSelf: 'stretch',
                                            maxWidth: '80%',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* product_name */}
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            {
                                              fontFamily: 'System',
                                              fontSize: 16,
                                              fontWeight: '400',
                                            },
                                            dimensions.width
                                          )}
                                          numberOfLines={2}
                                        >
                                          {sectionListData?.nom}
                                        </Text>
                                        {/* product_conditionning */}
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            {
                                              color: theme.colors.strong,
                                              fontFamily: 'System',
                                              fontSize: 12,
                                              fontStyle: 'italic',
                                              fontWeight: '400',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {sectionListData?.conditionnement}
                                        </Text>
                                        {/* precommande */}
                                        <>
                                          {!sectionListData?.precommande ? null : (
                                            <Text
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color: theme.colors.strong,
                                                  fontFamily: 'System',
                                                  fontSize: 12,
                                                  fontStyle: 'italic',
                                                  fontWeight: '400',
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {'En précommande'}
                                            </Text>
                                          )}
                                        </>
                                        {/* notes */}
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            {
                                              color: theme.colors.strong,
                                              fontFamily: 'System',
                                              fontSize: 12,
                                              fontStyle: 'italic',
                                              fontWeight: '400',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {sectionListData?.note}
                                        </Text>
                                      </View>
                                      {/* Prix */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'flex-end',
                                            justifyContent: 'flex-end',
                                            minWidth: 40,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            { fontSize: 15 },
                                            dimensions.width
                                          )}
                                        >
                                          {sectionListData?.prix}
                                          {' €'}
                                        </Text>

                                        <Text
                                          style={StyleSheet.applyWidth(
                                            {
                                              color: theme.colors.strong,
                                              fontSize: 10,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {'/ '}
                                          {sectionListData?.unit}
                                        </Text>
                                      </View>
                                    </View>
                                    <Divider
                                      style={StyleSheet.applyWidth(
                                        { height: 1 },
                                        dimensions.width
                                      )}
                                      color={theme.colors.divider}
                                    />
                                  </Touchable>
                                </View>
                              </>
                            );
                          }}
                          data={fetchData}
                          listKey={'YqgKbqaw'}
                          keyExtractor={sectionListData =>
                            sectionListData?.id ||
                            sectionListData?.uuid ||
                            JSON.stringify(sectionListData)
                          }
                          sectionKey={'cat_label'}
                          estimatedItemSize={250}
                          listComponent={'FlashList'}
                          numColumns={1}
                          onEndReachedThreshold={0.5}
                          showsHorizontalScrollIndicator={true}
                          showsVerticalScrollIndicator={true}
                          stickyHeader={true}
                        />
                      )}
                    </>
                    {/* FlashList_search */}
                    <>
                      {!textInputValue ? null : (
                        <FlashList
                          renderItem={({ item }) => {
                            const flashListSearchData = item;
                            return (
                              <>
                                {/* Product */}
                                <Touchable
                                  onPress={() => {
                                    try {
                                      navigation.navigate(
                                        'BottomTabNavigator',
                                        {
                                          screen: 'Commande',
                                          params: {
                                            screen: 'ProduitScreen',
                                            params: {
                                              precommande:
                                                flashListSearchData?.precommande,
                                              productID:
                                                flashListSearchData?.id,
                                              nom: flashListSearchData?.nom,
                                              unit: flashListSearchData?.unit,
                                              conditionning:
                                                flashListSearchData?.conditionnement,
                                              prix: flashListSearchData?.prix,
                                              provenane:
                                                flashListSearchData?.provenance,
                                              note: flashListSearchData?.note,
                                            },
                                          },
                                        }
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  style={StyleSheet.applyWidth(
                                    { maxWidth: '100%' },
                                    dimensions.width
                                  )}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'flex-start',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        paddingBottom: 8,
                                        paddingTop: 8,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Libellé */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignSelf: 'stretch',
                                          maxWidth: '80%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* product_name */}
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          {
                                            fontFamily: 'System',
                                            fontSize: 16,
                                            fontWeight: '400',
                                          },
                                          dimensions.width
                                        )}
                                        numberOfLines={2}
                                      >
                                        {(() => {
                                          const e = flashListSearchData?.nom;
                                          console.log(e);
                                          return e;
                                        })()}
                                      </Text>
                                      {/* product_conditionning */}
                                      <>
                                        {!flashListSearchData?.conditionnement ? null : (
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              {
                                                color: theme.colors.strong,
                                                fontFamily: 'System',
                                                fontSize: 12,
                                                fontStyle: 'italic',
                                                fontWeight: '400',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {
                                              flashListSearchData?.conditionnement
                                            }
                                          </Text>
                                        )}
                                      </>
                                      {/* precommande */}
                                      <>
                                        {!flashListSearchData?.precommande ? null : (
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              {
                                                color: theme.colors.strong,
                                                fontFamily: 'System',
                                                fontSize: 12,
                                                fontStyle: 'italic',
                                                fontWeight: '400',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {'En précommande'}
                                          </Text>
                                        )}
                                      </>
                                      {/* notes */}
                                      <>
                                        {!flashListSearchData?.note ? null : (
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              {
                                                color: theme.colors.strong,
                                                fontFamily: 'System',
                                                fontSize: 12,
                                                fontWeight: '400',
                                                textDecorationLine: 'underline',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {flashListSearchData?.note}
                                          </Text>
                                        )}
                                      </>
                                    </View>
                                    {/* Prix */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'flex-end',
                                          justifyContent: 'flex-end',
                                          minWidth: 40,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          { fontSize: 15 },
                                          dimensions.width
                                        )}
                                      >
                                        {flashListSearchData?.prix}
                                        {' €'}
                                      </Text>

                                      <Text
                                        style={StyleSheet.applyWidth(
                                          {
                                            color: theme.colors.strong,
                                            fontSize: 10,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {'/ '}
                                        {flashListSearchData?.unit}
                                      </Text>
                                    </View>
                                  </View>
                                  <Divider
                                    style={StyleSheet.applyWidth(
                                      { height: 1 },
                                      dimensions.width
                                    )}
                                    color={theme.colors.divider}
                                  />
                                </Touchable>
                              </>
                            );
                          }}
                          data={filterList_search(fetchData)}
                          listKey={'sKLngtOH'}
                          keyExtractor={flashListSearchData =>
                            flashListSearchData?.id ||
                            flashListSearchData?.uuid ||
                            JSON.stringify(flashListSearchData)
                          }
                          estimatedItemSize={30}
                          numColumns={1}
                        />
                      )}
                    </>
                  </>
                );
              }}
            </NinoxApi.FetchGetProduitsGET>
          </ScrollView>
        )}
      </>
      <>
        {!Constants['deliverydate'] ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                bottom: 0,
                height: 60,
                justifyContent: 'center',
                paddingBottom: 20,
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 20,
              },
              dimensions.width
            )}
          >
            {/* CartPreview */}
            <Button
              onPress={() => {
                try {
                  navigation.navigate('BottomTabNavigator', {
                    screen: 'PanierScreen',
                    params: { direct_view: false },
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors.primary,
                  fontFamily: 'System',
                  fontWeight: '700',
                  height: 35,
                  textAlign: 'center',
                },
                dimensions.width
              )}
              title={'Aperçu de la commande'}
            />
          </View>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(CatalogueScreen);
