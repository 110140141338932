import React from 'react';
import * as CustomCode from '../CustomCode';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  Button,
  NumberInput,
  ScreenContainer,
  Spacer,
  TextField,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Platform, Text, View, useWindowDimensions } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const ProduitScreen = props => {
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const ShowUnit = unit => {
    let response = unit == 'unité' ? false : true;
    return response;
  };

  const addToCart = (
    quantite,
    pieces,
    remarque,
    productID,
    name,
    unit,
    note,
    precommandeboolean
  ) => {
    const apercu = quantite.toString().concat(unit, ' ', name);
    let cartItem = {
      productId: productID,
      productname: name,
      unit: unit,
      qty: quantite,
      qty_piece: pieces,
      remarque: remarque,
      apercu: apercu,
      notecommande: note,
      precommande: precommandeboolean,
    };

    let newCart = [];

    if (typeof Constants.ShoppingCart !== 'string') {
      newCart = Constants.ShoppingCart.map(item => item);
    }

    newCart.push(cartItem);

    return newCart;
  };

  const { theme } = props;
  const { navigation } = props;

  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setNom(props.route?.params?.nom ?? '');
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);
  const [nom, setNom] = React.useState('');
  const [piece, setPiece] = React.useState('');
  const [quantite, setQuantite] = React.useState('');
  const [remarque, setRemarque] = React.useState('');

  return (
    <ScreenContainer
      style={StyleSheet.applyWidth(
        { justifyContent: 'space-around' },
        dimensions.width
      )}
      hasSafeArea={true}
    >
      <KeyboardAwareScrollView enableOnAndroid={false}>
        <View
          style={StyleSheet.applyWidth(
            { alignSelf: 'center', marginTop: 16, width: '90%' },
            dimensions.width
          )}
        >
          {/* Product_name */}
          <Text
            style={StyleSheet.applyWidth(
              {
                color: theme.colors.strong,
                fontFamily: 'System',
                fontSize: 30,
                fontWeight: '600',
                textAlign: 'center',
              },
              dimensions.width
            )}
          >
            {nom}
          </Text>
          {/* Product_conditionning */}
          <Text
            style={StyleSheet.applyWidth(
              { alignSelf: 'center', color: theme.colors.strong },
              dimensions.width
            )}
          >
            {props.route?.params?.conditionning ?? ''}
          </Text>
          <Spacer bottom={8} left={8} right={8} top={8} />
          {/* Pricing */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignContent: 'flex-end',
                alignItems: 'flex-end',
                flexDirection: 'row',
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'flex-end',
                  alignSelf: 'flex-end',
                  justifyContent: 'flex-end',
                  marginRight: 2,
                  width: '50%',
                },
                dimensions.width
              )}
            >
              {/* Pricing_amount */}
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors.strong,
                    fontSize: 20,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
              >
                {props.route?.params?.prix ?? ''}
                {'€'}
              </Text>
            </View>

            <View
              style={StyleSheet.applyWidth(
                {
                  alignSelf: 'flex-start',
                  justifyContent: 'flex-end',
                  marginLeft: 2,
                  width: '50%',
                },
                dimensions.width
              )}
            >
              {/* Pricing_unit */}
              <Text
                style={StyleSheet.applyWidth(
                  { color: theme.colors.strong },
                  dimensions.width
                )}
              >
                {'HT / '}
                {props.route?.params?.unit ?? ''}
              </Text>
            </View>
          </View>
          {/* Product_provenance */}
          <Text
            style={StyleSheet.applyWidth(
              {
                alignSelf: 'center',
                color: theme.colors.strong,
                fontFamily: 'System',
                fontStyle: 'italic',
                fontWeight: '300',
                marginBottom: 10,
                marginTop: 10,
                textAlign: 'center',
              },
              dimensions.width
            )}
          >
            {'cette semaine en provenance des fermes:\n'}
            {props.route?.params?.provenane ?? ''}
          </Text>
          <>
            {!(props.route?.params?.note ?? '') ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'flex-start',
                    backgroundColor: theme.colors['Divider'],
                    borderColor: theme.colors['Primary'],
                    borderRadius: 8,
                    borderWidth: 1,
                    justifyContent: 'center',
                  },
                  dimensions.width
                )}
              >
                {/* à noter */}
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      alignSelf: 'flex-start',
                      color: theme.colors.strong,
                      fontFamily: 'System',
                      fontWeight: '400',
                      marginBottom: 10,
                      marginLeft: 5,
                      marginTop: 10,
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                >
                  {'à noter: '}
                  {props.route?.params?.note ?? ''}
                </Text>
              </View>
            )}
          </>
        </View>
        <Spacer bottom={8} left={8} right={8} top={8} />
        <View
          style={StyleSheet.applyWidth(
            { alignSelf: 'center', width: '90%' },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'row',
                justifyContent: 'space-between',
                maxWidth: '100%',
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth({ width: '45%' }, dimensions.width)}
            >
              <View>
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors.strong,
                      fontFamily: 'System',
                      fontWeight: '600',
                    },
                    dimensions.width
                  )}
                >
                  {'Combien de '}
                  {props.route?.params?.unit ?? ''}
                  {' ?'}
                </Text>
                <>
                  {!(props.route?.params?.conditionning ?? '') ? null : (
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.strong,
                          fontFamily: 'System',
                          fontWeight: '400',
                        },
                        dimensions.width
                      )}
                    >
                      {'('}
                      {props.route?.params?.conditionning ?? ''}
                      {')'}
                    </Text>
                  )}
                </>
              </View>
              {/* unite_android */}
              <>
                {Platform.OS === 'ios' ? null : (
                  <NumberInput
                    onChangeText={newUniteAndroidValue => {
                      try {
                        setQuantite(newUniteAndroidValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: theme.colors.divider,
                        borderLeftWidth: 1,
                        borderRadius: 8,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                      },
                      dimensions.width
                    )}
                    clearTextOnFocus={true}
                    defaultValue={''}
                    keyboardType={'numeric'}
                    placeholder={'Renseigner la quantité désirée'}
                  />
                )}
              </>
              {/* unite_iOS */}
              <>
                {!(Platform.OS === 'ios') ? null : (
                  <NumberInput
                    onChangeText={newUniteIOSValue => {
                      try {
                        setQuantite(newUniteIOSValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: theme.colors.divider,
                        borderLeftWidth: 1,
                        borderRadius: 8,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                      },
                      dimensions.width
                    )}
                    clearTextOnFocus={true}
                    defaultValue={''}
                    keyboardType={'numbers-and-punctuation'}
                    placeholder={'Renseigner la quantité désirée'}
                  />
                )}
              </>
            </View>
            <>
              {!ShowUnit(props.route?.params?.unit ?? '') ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { width: '45%' },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.strong,
                        fontFamily: 'System',
                        fontWeight: '600',
                      },
                      dimensions.width
                    )}
                  >
                    {'Combien de pièces ?'}
                  </Text>
                  {/* a la piece */}
                  <NumberInput
                    onChangeText={newALaPieceValue => {
                      try {
                        setPiece(newALaPieceValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: theme.colors.divider,
                        borderLeftWidth: 1,
                        borderRadius: 8,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 8,
                      },
                      dimensions.width
                    )}
                    clearTextOnFocus={true}
                    defaultValue={''}
                    keyboardType={'numbers-and-punctuation'}
                    placeholder={'Renseigner la quantité désirée'}
                  />
                </View>
              )}
            </>
          </View>
          <Spacer bottom={16} left={8} right={8} top={16} />
          <View>
            {/* Remarque */}
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors.strong,
                  fontFamily: 'System',
                  fontWeight: '600',
                },
                dimensions.width
              )}
            >
              {'Aucune remarque ? Calibre spécifique ?'}
            </Text>
            {/* Sous-remarque */}
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors.strong,
                  fontFamily: 'System',
                  fontSize: 10,
                  fontStyle: 'italic',
                  fontWeight: '300',
                },
                dimensions.width
              )}
            >
              {
                'Mentionner également si cette volonté est "sine qua non", à savoir si nous n\'y arrivons vous préférez ne pas être livré du tout'
              }
            </Text>
            {/* remarque */}
            <TextField
              onChangeText={newRemarqueValue => {
                const textFieldValue = newRemarqueValue;
                try {
                  setRemarque(newRemarqueValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                {
                  borderColor: theme.colors.divider,
                  borderRadius: 8,
                  paddingBottom: 8,
                  paddingLeft: 8,
                  paddingRight: 8,
                  paddingTop: 8,
                  textAlign: 'left',
                },
                dimensions.width
              )}
              allowFontScaling={false}
              defaultValue={remarque}
              placeholder={'Remarque'}
              type={'solid'}
              underlineColor={theme.colors.light}
            />
          </View>
        </View>
      </KeyboardAwareScrollView>

      <View
        style={StyleSheet.applyWidth(
          {
            alignSelf: 'center',
            bottom: 0,
            flexDirection: 'row',
            height: 60,
            justifyContent: 'space-around',
            width: '90%',
          },
          dimensions.width
        )}
      >
        {/* AddToCart */}
        <Button
          onPress={() => {
            try {
              const updatedCart = addToCart(
                quantite,
                piece,
                remarque,
                props.route?.params?.productID ?? 38,
                nom,
                props.route?.params?.unit ?? '',
                props.route?.params?.note ?? '',
                props.route?.params?.precommande ?? ''
              );
              setGlobalVariableValue({
                key: 'ShoppingCart',
                value: updatedCart,
              });
              navigation.navigate('BottomTabNavigator', {
                screen: 'Commande',
                params: { screen: 'CatalogueScreen' },
              });
              setQuantite('');
              setPiece('');
              setRemarque('');
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            {
              backgroundColor: theme.colors.primary,
              fontFamily: 'System',
              fontWeight: '700',
              height: 35,
              textAlign: 'center',
              width: '40%',
            },
            dimensions.width
          )}
          title={'Commander'}
        />
        {/* Cancel */}
        <Button
          onPress={() => {
            try {
              navigation.navigate('BottomTabNavigator', {
                screen: 'Commande',
                params: { screen: 'CatalogueScreen' },
              });
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            {
              backgroundColor: theme.colors.background,
              borderBottomWidth: 2,
              borderColor: theme.colors.error,
              borderLeftWidth: 2,
              borderRadius: 8,
              borderRightWidth: 2,
              borderTopWidth: 2,
              color: theme.colors.error,
              fontFamily: 'System',
              fontWeight: '700',
              height: 35,
              textAlign: 'center',
              width: '40%',
            },
            dimensions.width
          )}
          title={'Annuler'}
        />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(ProduitScreen);
