import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import * as GlobalVariables from '../config/GlobalVariableContext';

export const collecteDirectGET = (Constants, { zone_id }, handlers = {}) =>
  fetch(
    `https://api.ninox.com/v1/teams/t6qSSGdCRhAJHHauW/databases/agzi4cu61a2b/query?query=(select Collecte where sum(Collecte,1000*60*60*7)>today() and 'Permettre en direct'=true and cnt(Zone[Zone=${
      zone_id ?? ''
    }])>0 order by Collecte).{fermier:Producteur.'Nom de la ferme',label_order_collecte:concat(Producteur.'Nom de la ferme',format(sum(Collecte,1000*60*60*2),"ddd DD MMM hh:mm","fr")),heurecollecte:Collecte,logistique:text(Logistique),direct:'Permettre en direct',franco:Producteur.'Franco pour livraisons direct',produits:Arrivage.{label_order_collecte:concat(Collecte.Producteur.'Nom de la ferme',format(sum(Collecte.Collecte,1000*60*60*2),"ddd DD MMM hh:mm","fr")),fermier:Collecte.Producteur.'Nom de la ferme',collecte_id:number(Collecte.ID),heurecollecte:Collecte.Collecte,arrivageID:number(ID),product_id:number(Produit.ID),label:Produit.label,unit:Produit.Famille.'Unité de vente'.'Unité',famille:Produit.Famille.Nom,spec:Produit.Specificite,cond:Produit.Conditionnement,potentiel:'Quantité potentielle',demande:'Quantité demandée',facture:'Quantité facturée',prix_total:round('Coût HT'*100)/100,prix_ferme:round('Coût HT'*100)/100,com:0}}`,
    {
      headers: {
        Authorization: 'Bearer 852477e0-c4b0-11eb-907c-51bcaf6e44b8',
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useCollecteDirectGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['collecte_directs', args],
    () => collecteDirectGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchCollecteDirectGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  zone_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useCollecteDirectGET(
    { zone_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCollecteDirect: refetch });
};

export const détailsCommandeGET = (Constants, { idorder }, handlers = {}) =>
  fetch(
    `https://api.ninox.com/v1/teams/t6qSSGdCRhAJHHauW/databases/agzi4cu61a2b/query?query=(select Panier_pro where Commande=${
      idorder ?? ''
    }).{Produit:Produit.SKU,'aupoids':'au poids','alapiece':'à la pièce',remarque:remarque,'facture':'facturé',Prix:'Prix HT Catalogue',unit:Produit.Famille.'Unité de vente'.'Unité',Remise:Remise,'Montant total':'Montant HT facturé'}`,
    {
      headers: {
        Authorization: 'Bearer 852477e0-c4b0-11eb-907c-51bcaf6e44b8',
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useDétailsCommandeGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['ninoxDétailsCommandeGET', args],
    () => détailsCommandeGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['ninoxDétailsCommandeGETS']),
    }
  );
};

export const FetchDétailsCommandeGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  idorder,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useDétailsCommandeGET(
    { idorder },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchDétailsCommande: refetch });
};

export const getProduitsGET = (
  Constants,
  { dateday, datemonth, dateyear },
  handlers = {}
) =>
  fetch(
    `https://api.ninox.com/v1/teams/t6qSSGdCRhAJHHauW/databases/agzi4cu61a2b/query?query=(select Produit where orfx(cnt(Arrivage[Peremption>date(${
      dateyear ?? ''
    },${datemonth ?? ''},${dateday ?? ''}) and Collecte.Collecte<=datetime(${
      dateyear ?? ''
    },${datemonth ?? ''},${
      dateday ?? ''
    },9)])>0,'Précommande'=true) and 'Dispo Pro' = true order by concat(Famille.'Catégorie2'.Nom,label)).{id:number(ID),nom:label,precommande:'Précommande',conditionnement:'Conditionnement',prix:'Prix HT [PRO]',cat_label:text(Famille.'Catégorie2'.Label),catid:number(Famille.'Catégorie2'.ID),provenance:Provenance,note:Note,unit:text(Famille.'Unité de vente'.'Unité')}`,
    {
      headers: {
        Authorization: 'Bearer 852477e0-c4b0-11eb-907c-51bcaf6e44b8',
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetProduitsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['produits', args],
    () => getProduitsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetProduitsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  dateday,
  datemonth,
  dateyear,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetProduitsGET(
    { dateday, datemonth, dateyear },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetProduits: refetch });
};

export const historiqueCommandeEPGET = (
  Constants,
  { emailclient },
  handlers = {}
) =>
  fetch(
    `https://api.ninox.com/v1/teams/t6qSSGdCRhAJHHauW/databases/agzi4cu61a2b/query?query=(select Commande_pro where cnt(Professionnels.Chefs['E-mail'="${
      emailclient ?? ''
    }"])>0 order by rsort).{id:number(ID),resto:Professionnels.'Nom commercial',Livraison:'Livraison','Montant HT final':'Montant total HT',test_enattente:Statut=2}`,
    {
      headers: {
        Authorization: 'Bearer 852477e0-c4b0-11eb-907c-51bcaf6e44b8',
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useHistoriqueCommandeEPGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['ninoxHistoriqueCommandeEPGET', args],
    () => historiqueCommandeEPGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['ninoxHistoriqueCommandeEPGETS']),
    }
  );
};

export const FetchHistoriqueCommandeEPGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  emailclient,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useHistoriqueCommandeEPGET(
    { emailclient },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchHistoriqueCommandeEP: refetch,
  });
};

export const historiqueCommandeDirectGET = (
  Constants,
  { emailclient },
  handlers = {}
) =>
  fetch(
    `https://api.ninox.com/v1/teams/t6qSSGdCRhAJHHauW/databases/agzi4cu61a2b/query?query=(select Commande_pro_direct where cnt(Professionnels.Chefs['E-mail'="${
      emailclient ?? ''
    }"])>0 order by rsort).{id:number(ID),label_order_collecte:concat(first(Panier_pro_direct.Arrivage.Collecte.Producteur.'Nom de la ferme'),format(sum(first(Panier_pro_direct.Arrivage.Collecte.Collecte),1000*60*60*2),"ddd DD MMM hh:mm","fr")),test_apreparer:if Statut=1 then true end,test_prete:if Statut=2 then true end,test_livree:if Statut=3 then true end,statut:text(Statut),resto:Professionnels.'Nom commercial',Ferme:unique(Panier_pro_direct.Arrivage.Collecte.Producteur.'Nom de la ferme'),Livraison:'Date',Montant_total:round('Montant total HT'*100)/100,'Montant_com':round('Montant com HT'*100)/100,Montant_ferme:'Montant ferme HT',Panier_pro_direct:Panier_pro_direct.{Produit:Arrivage.Produit.SKU,'aupoids':'au poids','alapiece':'à la pièce',remarque:Remarque,'facture':'facturé',Prix:'Prix HT direct',unit:Arrivage.Produit.Famille.'Unité de vente'.'Unité',Remise:Remise,'Montant ferme':'Montant HT ferme facturé','Montant total':'Montant total HT',com:'Commission HT'}}`,
    {
      headers: {
        Authorization: 'Bearer 852477e0-c4b0-11eb-907c-51bcaf6e44b8',
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useHistoriqueCommandeDirectGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['order_collectes', args],
    () => historiqueCommandeDirectGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchHistoriqueCommandeDirectGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  emailclient,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useHistoriqueCommandeDirectGET(
    { emailclient },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchHistoriqueCommandeDirect: refetch,
  });
};

export const identificationRestoGET = (
  Constants,
  { clientid },
  handlers = {}
) =>
  fetch(
    `https://api.ninox.com/v1/teams/t6qSSGdCRhAJHHauW/databases/agzi4cu61a2b/query?query=(select Restaurant where 'identifiant app'="${
      clientid ?? ''
    }").{nomresto:'Nom commercial',idninox:ID}`,
    {
      headers: {
        Authorization: 'Bearer 852477e0-c4b0-11eb-907c-51bcaf6e44b8',
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useIdentificationRestoGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['ninoxIdentificationRestoGET', args],
    () => identificationRestoGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['ninoxIdentificationRestoGETS']),
    }
  );
};

export const FetchIdentificationRestoGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  clientid,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useIdentificationRestoGET(
    { clientid },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchIdentificationResto: refetch,
  });
};

export const joursGET = (Constants, _args, handlers = {}) =>
  fetch(
    `https://api.ninox.com/v1/teams/t6qSSGdCRhAJHHauW/databases/agzi4cu61a2b/query?query=(select Livraisons where Date>=today() and Montrer=true order by Date).{label:Texte,value:Date}`,
    {
      headers: {
        Authorization: 'Bearer 852477e0-c4b0-11eb-907c-51bcaf6e44b8',
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useJoursGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(['jours', args], () => joursGET(Constants, args, handlers), {
    refetchInterval,
  });
};

export const FetchJoursGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useJoursGET({}, { refetchInterval, handlers: { onData, ...handlers } });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchJours: refetch });
};

export const zoneProGET = (Constants, { emailinput }, handlers = {}) =>
  fetch(
    `https://api.ninox.com/v1/teams/t6qSSGdCRhAJHHauW/databases/agzi4cu61a2b/query?query=first((select Restaurant where cnt(Chefs['E-mail'="${
      emailinput ?? ''
    }"])>0).Zone)`,
    {
      headers: {
        Authorization: 'Bearer 852477e0-c4b0-11eb-907c-51bcaf6e44b8',
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useZoneProGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(['zone', args], () => zoneProGET(Constants, args, handlers), {
    refetchInterval,
    onSuccess: () => queryClient.invalidateQueries(['zones']),
  });
};

export const FetchZoneProGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  emailinput,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useZoneProGET(
    { emailinput },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchZonePro: refetch });
};

export const fermierDirectGET = (Constants, { zone_id }, handlers = {}) =>
  fetch(
    `https://api.ninox.com/v1/teams/t6qSSGdCRhAJHHauW/databases/agzi4cu61a2b/query?query=(select Ferme where cnt(Collecte[date(Collecte)>=today() and 'Permettre en direct'=true and cnt(Zone[Zone=${
      zone_id ?? ''
    }])>0])>0).{label:'Nom de la ferme',value:number(ID)}`,
    {
      headers: {
        Authorization: 'Bearer 852477e0-c4b0-11eb-907c-51bcaf6e44b8',
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useFermierDirectGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['collecte_direct_fermiers', args],
    () => fermierDirectGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchFermierDirectGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  zone_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useFermierDirectGET(
    { zone_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchFermierDirect: refetch });
};
