import React from 'react';

const getDate_String = deliverydate => {
  const datetoformat = new Date(Number(deliverydate));
  const options = { weekday: 'short', month: 'short', day: 'numeric' };
  const datestring = datetoformat.toLocaleDateString('fr-FR', options);

  return datestring;
};

export default getDate_String;
