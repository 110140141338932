import * as React from 'react';
import { I18nManager, Platform, StyleSheet, Text, View } from 'react-native';
import { systemWeights } from 'react-native-typography';
import { Icon, Touchable } from '@draftbit/ui';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import theme from './themes/DraftbitTheme.js';
import LinkingConfiguration from './LinkingConfiguration.js';

import AccueilScreen from './screens/AccueilScreen';
import CatalogueScreen from './screens/CatalogueScreen';
import CommandeconfirmeScreen from './screens/CommandeconfirmeScreen';
import H1HistoriqueScreen from './screens/H1HistoriqueScreen';
import H2DtailscommandeScreen from './screens/H2DtailscommandeScreen';
import M1MarchScreen from './screens/M1MarchScreen';
import M2ProduitScreen from './screens/M2ProduitScreen';
import PanierScreen from './screens/PanierScreen';
import ProduitScreen from './screens/ProduitScreen';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

function Placeholder() {
  return (
    <View
      style={{
        flex: 1,
        backgroundColor: '#131A2A',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 36,
      }}
    >
      <Text
        style={{
          textAlign: 'center',
          fontSize: 24,
          fontWeight: 'bold',
          marginBottom: 12,
          color: '#FFF',
        }}
      >
        Missing Screen
      </Text>
      <Text
        style={{
          textAlign: 'center',
          fontSize: 16,
          color: '#FFF',
          marginBottom: 8,
        }}
      >
        This screen is not in a navigator.
      </Text>
      <Text
        style={{
          textAlign: 'center',
          fontSize: 16,
          color: '#FFF',
          marginBottom: 8,
        }}
      >
        Go to Navigation mode, and click the + (plus) icon in the Navigator tab
        on the left side to add this screen to a Navigator.
      </Text>
      <Text style={{ textAlign: 'center', fontSize: 16, color: '#FFF' }}>
        If the screen is in a Tab Navigator, make sure the screen is assigned to
        a tab in the Config panel on the right.
      </Text>
    </View>
  );
}
function Commande() {
  return (
    <Stack.Navigator headerMode="none" initialRouteName="CatalogueScreen">
      <Stack.Screen
        name="ProduitScreen"
        component={ProduitScreen}
        options={{
          title: '2. Produit',
        }}
      />
      <Stack.Screen
        name="CatalogueScreen"
        component={CatalogueScreen}
        options={{
          title: '1. Catalogue',
        }}
      />
      <Stack.Screen
        name="CommandeconfirmeScreen"
        component={CommandeconfirmeScreen}
        options={{
          headerTransparent: false,
          title: '4. Commande confirmée',
        }}
      />
    </Stack.Navigator>
  );
}

function Historique() {
  return (
    <Stack.Navigator
      headerMode="none"
      initialRouteName="H1HistoriqueScreen"
      screenOptions={{
        headerBackImage: ({ tintColor }) => (
          <Icon
            name="Feather/arrow-left"
            size={Platform.OS === 'ios' ? 21 : 24}
            color={tintColor}
            style={[styles.headerIcon, styles.headerIconLeft]}
          />
        ),
        headerBackTitle: 'Retour',
      }}
    >
      <Stack.Screen
        name="H1HistoriqueScreen"
        component={H1HistoriqueScreen}
        options={{
          headerTitle: 'Historique',
          title: 'H1. Historique',
        }}
      />
      <Stack.Screen
        name="H2DtailscommandeScreen"
        component={H2DtailscommandeScreen}
        options={{
          headerTitle: 'Détails commande',
          title: 'H2. Détails commande',
        }}
      />
    </Stack.Navigator>
  );
}

function BottomTabNavigator() {
  return (
    <Tab.Navigator
      initialRouteName="Commande"
      tabBarOptions={{
        showLabel: true,
        showIcon: false,
        style: { borderTopColor: 'transparent' },
      }}
      backBehavior="history"
    >
      <Tab.Screen
        name="Commande"
        component={Commande}
        options={{
          tabBarIcon: ({ focused, color }) => (
            <Icon
              name="MaterialCommunityIcons/carrot"
              size={25}
              color={focused ? theme.colors.secondary : color}
            />
          ),
          tabBarLabel: 'Epicerie P.',
          title: 'Commande',
        }}
      />
      <Tab.Screen
        name="PanierScreen"
        component={PanierScreen}
        options={{
          title: '3. Panier',
          tabBarIcon: ({ focused, color }) => (
            <Icon
              name="Feather/shopping-cart"
              size={25}
              color={focused ? theme.colors.secondary : color}
            />
          ),
          tabBarLabel: 'Panier',
        }}
      />
      <Tab.Screen
        name="Historique"
        component={Historique}
        options={{
          tabBarIcon: ({ focused, color }) => (
            <Icon
              name="MaterialCommunityIcons/history"
              size={25}
              color={focused ? theme.colors.secondary : color}
            />
          ),
          tabBarLabel: 'Historique',
          title: 'Historique',
        }}
      />
    </Tab.Navigator>
  );
}

function Auth() {
  return (
    <Stack.Navigator headerMode="none" initialRouteName="AccueilScreen">
      <Stack.Screen
        name="AccueilScreen"
        component={AccueilScreen}
        options={{
          title: '.Accueil',
        }}
      />
    </Stack.Navigator>
  );
}

export default function RootAppNavigator() {
  return (
    <NavigationContainer linking={LinkingConfiguration}>
      <Stack.Navigator
        headerMode="none"
        initialRouteName="Auth"
        screenOptions={{
          headerBackTitle: ' ',
        }}
      >
        <Stack.Screen
          name="BottomTabNavigator"
          component={BottomTabNavigator}
        />
        <Stack.Screen name="Auth" component={Auth} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  headerIcon: Platform.select({
    ios: {
      marginVertical: 12,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
    default: {
      margin: 3,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
  }),
  headerIconLeft: Platform.select({
    ios: {
      marginRight: 6,
    },
  }),
  headerIconRight: Platform.select({
    ios: {
      marginLeft: 6,
    },
  }),
  headerContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    ...Platform.select({
      ios: null,
      default: {
        marginVertical: 3,
        marginHorizontal: 11,
      },
    }),
  },
  headerContainerLeft: Platform.select({
    ios: {
      marginLeft: 8,
    },
  }),
  headerContainerRight: Platform.select({
    ios: {
      marginRight: 8,
    },
  }),
  headerLabelWrapper: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  headerLabel: {
    fontSize: 17,
    letterSpacing: 0.35,
  },
});
