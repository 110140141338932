import React from 'react';
import * as CustomCode from '../CustomCode';
import * as NinoxApi from '../apis/NinoxApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  AccordionGroup,
  Button,
  Divider,
  Picker,
  ScreenContainer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  Image,
  Modal,
  ScrollView,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import { Fetch } from 'react-request';

const M1MarchScreen = props => {
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;

  const getDateDay = date => {
    const datePicker = new Date(date);
    const datePicker_day = datePicker.getDate();
    return datePicker_day;
  };

  const setDeliveryDate_today = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    return tomorrow.setDate(tomorrow.getDate() + 1);
  };

  const getDateYear = date => {
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.
    const datePicker = new Date(date);
    const datePicker_year = datePicker.getFullYear();
    return datePicker_year;
    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */
  };

  const getDateMonth = date => {
    const datePicker = new Date(date);
    const datePicker_month = datePicker.getMonth() + 1;
    return datePicker_month;
  };

  const LivraisonRemplie = datePicker => {
    let date = datePicker;
    let response = date > 0 ? false : true;
    return response;
  };

  const filterList = list => {
    let lowerfiltertext = textInputValue.toLowerCase();
    let newList = list.filter(item =>
      item.label.toLowerCase().includes(lowerfiltertext)
    );
    return newList;
  };

  const getDateString = deliverydate => {
    const datetoformat = new Date(deliverydate);
    const options = {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    const datestring = datetoformat.toLocaleDateString('fr-FR', options);

    return datestring;
  };

  const { theme } = props;
  const { navigation } = props;

  const [datePickerValue, setDatePickerValue] = React.useState(new Date());
  const [pickerValue, setPickerValue] = React.useState('');
  const [pickerValue2, setPickerValue2] = React.useState('');
  const [show_direct, setShow_direct] = React.useState(false);
  const [textInputValue, setTextInputValue] = React.useState('');

  return (
    <ScreenContainer
      style={StyleSheet.applyWidth(
        { flex: 1, marginLeft: 20, marginRight: 20 },
        dimensions.width
      )}
      hasSafeArea={true}
      scrollable={false}
    >
      <Modal visible={show_direct} animationType={'none'} />
      <View
        style={StyleSheet.applyWidth(
          {
            alignContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 10,
            marginLeft: 20,
            marginRight: 20,
            marginTop: 10,
          },
          dimensions.width
        )}
      >
        <Text
          style={StyleSheet.applyWidth(
            {
              alignSelf: 'flex-start',
              color: theme.colors.strong,
              fontFamily: 'PTSerif_400Regular_Italic',
              fontSize: 25,
            },
            dimensions.width
          )}
        >
          {'Le Marché en Direct'}
        </Text>
        <Image
          style={StyleSheet.applyWidth(
            { height: 60, width: 60 },
            dimensions.width
          )}
          resizeMode={'cover'}
          source={Images.CarotteDetouree}
        />
      </View>
      {/* Filtres */}
      <AccordionGroup
        style={StyleSheet.applyWidth(
          { flexDirection: 'row', fontSize: 14 },
          dimensions.width
        )}
        label={'Filtrer les mercuriales'}
        caretSize={24}
        icon={'Feather/filter'}
        iconSize={24}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: 'flex-end',
              alignItems: 'flex-end',
              alignSelf: 'flex-end',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
            },
            dimensions.width
          )}
        >
          <NinoxApi.FetchFermierDirectGET zone_id={1}>
            {({ loading, error, data, refetchFermierDirect }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <>
                  {/* Ferme */}
                  <Picker
                    onValueChange={newFermeValue => {
                      try {
                        setPickerValue2(newFermeValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 2,
                        borderColor: theme.colors.light,
                        color: theme.colors.strong,
                        fontSize: 14,
                        textAlign: 'left',
                      },
                      dimensions.width
                    )}
                    options={fetchData}
                    value={pickerValue2}
                    label={'Filtrer par ferme'}
                    leftIconMode={'inset'}
                    placeholder={'Filtrer par ferme'}
                    placeholderTextColor={theme.colors.light}
                    type={'underline'}
                  />
                </>
              );
            }}
          </NinoxApi.FetchFermierDirectGET>
        </View>
        {/* filter */}
        <TextInput
          onChangeText={newFilterValue => {
            try {
              setTextInputValue(newFilterValue);
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            {
              borderBottomWidth: 1,
              borderColor: theme.colors.divider,
              borderLeftWidth: 1,
              borderRadius: 8,
              borderRightWidth: 1,
              borderTopWidth: 1,
              marginTop: 10,
              paddingBottom: 8,
              paddingLeft: 8,
              paddingRight: 8,
              paddingTop: 8,
            },
            dimensions.width
          )}
          value={textInputValue}
          placeholder={'Rechercher un produit'}
        />
      </AccordionGroup>
      <Divider
        style={StyleSheet.applyWidth(
          { height: 2, marginBottom: 15, marginTop: 15 },
          dimensions.width
        )}
        color={theme.colors.divider}
      />
      <ScrollView bounces={true} showsVerticalScrollIndicator={true}>
        <NinoxApi.FetchCollecteDirectGET zone_id={1}>
          {({ loading, error, data, refetchCollecteDirect }) => {
            const fetchData = data?.json;
            if (loading) {
              return <ActivityIndicator />;
            }

            if (error || data?.status < 200 || data?.status >= 300) {
              return <ActivityIndicator />;
            }

            return (
              <FlatList
                renderItem={({ item }) => {
                  const listData = item;
                  return (
                    <AccordionGroup
                      style={StyleSheet.applyWidth(
                        { fontSize: 16, marginBottom: 10 },
                        dimensions.width
                      )}
                      label={listData?.label_order_collecte}
                      caretSize={24}
                      expanded={true}
                      iconSize={24}
                    >
                      <>
                        {!listData?.franco ? null : (
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors.strong,
                                marginBottom: 10,
                                textDecorationLine: 'underline',
                              },
                              dimensions.width
                            )}
                          >
                            {'Franco de '}
                            {listData?.franco}
                            {'€ avec cette ferme'}
                          </Text>
                        )}
                      </>
                      <FlatList
                        renderItem={({ item }) => {
                          const listData = item;
                          return (
                            <>
                              {/* Product */}
                              <Touchable
                                onPress={() => {
                                  try {
                                    navigation.navigate('M2ProduitScreen', {
                                      label: listData?.label,
                                      ferme: listData?.fermier,
                                      collecte_id: listData?.collecte_id,
                                      deliverytimestring:
                                        listData?.heurecollecte,
                                      label_order_collecte:
                                        listData?.label_order_collecte,
                                      arrivage_ID: listData?.arrivageID,
                                      unit: listData?.unit,
                                      conditionning: listData?.cond,
                                      productID: listData?.product_id,
                                      prix_total: listData?.prix_total,
                                      prix_ferme: listData?.prix_ferme,
                                      com: listData?.com,
                                    });
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                style={StyleSheet.applyWidth(
                                  { maxWidth: '100%' },
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'flex-start',
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                      paddingBottom: 8,
                                      paddingLeft: 10,
                                      paddingTop: 8,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Libellé */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { alignSelf: 'stretch', maxWidth: '80%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* product_name */}
                                    <Text
                                      style={StyleSheet.applyWidth(
                                        {
                                          fontFamily: 'System',
                                          fontSize: 14,
                                          fontWeight: '600',
                                        },
                                        dimensions.width
                                      )}
                                      numberOfLines={2}
                                    >
                                      {listData?.label}
                                    </Text>

                                    <View
                                      style={StyleSheet.applyWidth(
                                        { flexDirection: 'row' },
                                        dimensions.width
                                      )}
                                    >
                                      {/* product_unit */}
                                      <>
                                        {listData?.cond ? null : (
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              {
                                                color: theme.colors.strong,
                                                fontFamily: 'System',
                                                fontSize: 12,
                                                fontStyle: 'italic',
                                                fontWeight: '400',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {'par '}
                                            {listData?.unit}
                                          </Text>
                                        )}
                                      </>
                                      {/* product_conditionning */}
                                      <>
                                        {!listData?.cond ? null : (
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              {
                                                color: theme.colors.strong,
                                                fontFamily: 'System',
                                                fontSize: 12,
                                                fontStyle: 'italic',
                                                fontWeight: '400',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {'conditionné par '}
                                            {listData?.cond}
                                          </Text>
                                        )}
                                      </>
                                    </View>
                                  </View>
                                  {/* Prix */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'flex-end',
                                        justifyContent: 'flex-end',
                                        minWidth: 40,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      style={StyleSheet.applyWidth(
                                        { fontSize: 15 },
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.prix_total}
                                      {' €'}
                                    </Text>

                                    <Text
                                      style={StyleSheet.applyWidth(
                                        {
                                          color: theme.colors.strong,
                                          fontSize: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {'/ '}
                                      {listData?.unit}
                                    </Text>
                                  </View>
                                </View>
                                <Divider
                                  style={StyleSheet.applyWidth(
                                    { height: 1, marginLeft: 10 },
                                    dimensions.width
                                  )}
                                  color={theme.colors.divider}
                                />
                              </Touchable>
                            </>
                          );
                        }}
                        data={filterList(listData?.produits)}
                        listKey={JSON.stringify(filterList(listData?.produits))}
                        keyExtractor={listData =>
                          listData?.id ||
                          listData?.uuid ||
                          JSON.stringify(listData)
                        }
                        contentContainerStyle={StyleSheet.applyWidth(
                          { flex: 1 },
                          dimensions.width
                        )}
                        numColumns={1}
                      />
                    </AccordionGroup>
                  );
                }}
                data={fetchData}
                listKey={'lcC3arAZ'}
                keyExtractor={listData =>
                  listData?.id || listData?.uuid || JSON.stringify(listData)
                }
                numColumns={1}
              />
            );
          }}
        </NinoxApi.FetchCollecteDirectGET>
      </ScrollView>

      <View
        style={StyleSheet.applyWidth(
          {
            bottom: 0,
            height: 60,
            justifyContent: 'center',
            paddingBottom: 20,
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 20,
          },
          dimensions.width
        )}
      >
        {/* CartPreview */}
        <Button
          onPress={() => {
            try {
              navigation.navigate('BottomTabNavigator', {
                screen: 'PanierScreen',
                params: { direct_view: true },
              });
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            {
              backgroundColor: theme.colors.primary,
              fontFamily: 'System',
              fontWeight: '700',
              height: 35,
              textAlign: 'center',
            },
            dimensions.width
          )}
          title={'Aperçu de la commande'}
        />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(M1MarchScreen);
