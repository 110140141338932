import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import * as GlobalVariables from '../config/GlobalVariableContext';

export const orderCreationPOST = (
  Constants,
  { customeremail, day, month, remarque, shoppingcart, year },
  handlers = {}
) =>
  fetch(`https://hook.eu1.make.com/j40gcsajc3onhb0qst2ntyda5ki9r3hm`, {
    body: JSON.stringify({
      delivery_day: day,
      delivery_mont: month,
      delivery_year: year,
      customer_email: customeremail,
      products: shoppingcart,
      remarque: remarque,
    }),
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useOrderCreationPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => orderCreationPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Commande', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Commande');
        queryClient.invalidateQueries('Commandes');
      },
    }
  );
};

export const FetchOrderCreationPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  customeremail,
  day,
  month,
  remarque,
  shoppingcart,
  year,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useOrderCreationPOST(
    { customeremail, day, month, remarque, shoppingcart, year },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchOrderCreation: refetch });
};

export const orderCollecteDirectPOST = (
  Constants,
  { cart, collecte_id, customeremail, ferme, remarque },
  handlers = {}
) =>
  fetch(`https://hook.eu1.make.com/3dcvtl52kyvi33e4g483p3x7p3h3lf72`, {
    body: JSON.stringify({
      collecte_id: collecte_id,
      customer_email: customeremail,
      products: cart,
      remarque: remarque,
      ferme: ferme,
    }),
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useOrderCollecteDirectPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      orderCollecteDirectPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData(
            'Order_collecte_direct',
            previousValue
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Order_collecte_direct');
        queryClient.invalidateQueries('Order_collecte_directs');
      },
    }
  );
};

export const FetchOrderCollecteDirectPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  cart,
  collecte_id,
  customeremail,
  ferme,
  remarque,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useOrderCollecteDirectPOST(
    { cart, collecte_id, customeremail, ferme, remarque },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchOrderCollecteDirect: refetch,
  });
};
